@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom classes */

/* BUTTONS */
.btn-sea-green {
  @apply bg-sea-green text-white rounded-lg p-3;
}

.btn-sea-green-thick {
  @apply bg-sea-green text-white rounded-lg py-4 px-12;
}
.btn-sea-green-thick-xs {
  @apply bg-sea-green text-white rounded-md px-5 py-2;
}

.white-div {
  @apply bg-white p-5;
}

.category-content-wrapper-div {
  @apply flex flex-col gap-8 rounded-xl bg-white;
}

.form-outline-wrapper-div {
  @apply rounded-xl border border-grey-6 flex flex-col;
}

.search-bar-placeholder::placeholder {
  color: rgba(45, 45, 45, 0.3);
}

.product-card-shadow {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
}

.corner-ribbon {
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  color: black;
  transform: rotate(-35deg);
  -webkit-transform: rotate(-35deg);
}

.corner-ribbon.yellow {
  background: #dfe223;
}

.corner-ribbon.green {
  background: rgba(165, 226, 35, 1);
  /* background: rgb(113, 206, 37) */
}

.corner-ribbon.mustard {
  background: rgba(226, 161, 35, 1);
}

.pagination-gradient {
  background: var(
    --gradient,
    linear-gradient(54deg, #6515c4 1.11%, #ad0aef 45.97%, #b435f6 88.1%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--gradient, #6515c4);
  @apply px-4 py-2;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5.5px);
  z-index: 99999;
}

.login-form-input-div {
  @apply border-[1.5px] border-black-1 w-full p-5 pl-10 rounded-full bg-white;
}

.login-form-input-field {
  @apply outline-none placeholder-grey-13 text-xl w-full;
}

.login-form-pwd-wrapper {
  @apply border-[1.5px] border-black-1 w-full p-3 rounded-full flex bg-white;
}

.login-form-pwd-div {
  @apply border-r-[1.5px] p-2 w-3/4 border-r-black-1 pl-10;
}

.login-form-pwd-input-field {
  @apply outline-none placeholder-grey-13 text-xl w-full;
}

/* Box Shadows */
.box-shadow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
}

.box-shadow1 {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.box-shadow3 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box-shadow4 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.box-shadow2 {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.delete-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
  /* background-color: rgba(252, 239, 239, 0.80); */
  /* @apply p-1 rounded-full border-none */
}

.relative {
  position: relative;
}
